import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import mapSegment from "../utils/mapSegment";
import { base, storage } from "../../base";
import {
  stringToDate,
  minDate,
  isThisDuplicate,
  collectFootnotes,
} from "../utils/utilities";

import InvalidRecords from "../InvalidRecords";
import Footnotes from "../footnotes/Footnotes";
import $ from "jquery";
import InvalidReport from "../groupReports/InvalidReport";
import { verifyColumns } from "../utils/dataService";
import ReportBase from "../groupReports/ReportBase";
import "./Report4.scss";

class Report4 extends Component {
  footnotesColumnName = "report-4-footnotes";
  state = {
    body: null,
    segments: null,
    companies: null,
    months: {},
    notAvailable: []
  };

  headersAsKeys = [
    "Condition treated",
    "Segment",
    "Product name",
    "MOA (Revised)",
    "Compound phase",
    "Company Name",
    "Est. Launch",
  ];

  invalidHeaders = [
    "H/M/L; New; NA",
    "NCT link",
    "Segment",
    "Product name",
    "RoA",
    "Compound phase",
    "Company Name",
    "Est. Launch",
    "Duplicated",
  ];

  selectedMonth = [];
  footnotes = [];
  generalfootnotes = [];
  leftBehind = [];
  auxBody = [];

  // three event always show in the footnotes rule.
  eventTypes = [
    "Regulatory / Other Development",
    "Trial Initiation/ Results",
    "Commercial",
  ];

  fetchCompanies = async () => {
    let companies = await base.get("companies", {
      context: this,
      withIds: true
    });
    companies = await this.mapImageUrl(companies);
    this.setState({ companies });
    this.segmentTdFix();
  };

  mapImageUrl = async (data) => {
    let dWithImage = await data.map(async (d) => {
      const dd = { ...d };
      try {
        const thumb = this.thumb(d.logo, 80);
        if (d.logo) dd.logo = await storage.ref(thumb).getDownloadURL();
      } catch {
        if (d.logo) dd.logo = await storage.ref(d.logo).getDownloadURL();
      } finally {
        return dd;
      }
    });
    return await Promise.all(dWithImage);
  };

  thumb = (logo, size) => {
    const ext = logo.split(".").pop();
    if (ext === "svg") return logo;

    const dir = logo.split("/")[0];
    const name = logo.split("/")[1];
    return `${dir}/thumb@${size}_${name}`;
  };

  validation() {
    const notAvailable = verifyColumns(this.props.data);
    if (notAvailable.length) {
      this.setState({ notAvailable })
      return false;
    }
    return true;
  }

  componentDidMount() {
    const data = this.props.data;
    if (!this.validation()) return;

    this.fetchCompanies();

    const fileHeaders = data[0];

    const endIndex = fileHeaders.indexOf("Events beyond 6-month Timeframe");
    // debugger;

    if (endIndex !== -1) {
      this.selectedMonth = fileHeaders.slice(endIndex - 8, endIndex);

      this.headersAsKeys = [...this.headersAsKeys, ...this.selectedMonth];
      const months = {};
      this.selectedMonth.forEach((month) => {
        months[month] = true;
      });
      this.setState({ months });
    }
    let conference;
    let auxSegments = [];
    data.forEach((row, index) => {
      if (index === 0) return;
      const processedObject = row.reduce((object, cell, index) => {
        object[fileHeaders[index]] = cell;
        return object;
      }, {});

      //collecting the conference data
      if (processedObject["H/M/L; New; NA"] === "Conference") {
        conference = this.selectedMonth.map((value) => processedObject[value]);
        return;
      }

      //collecting general footnotes
      if (processedObject["report-4-general-footnotes"]) {
        this.generalfootnotes.push(
          processedObject["report-4-general-footnotes"]
        );
      }

      if (["HH"].includes(processedObject["H/M/L; New; NA"])) {
        const isDuplicate = isThisDuplicate(this.auxBody, processedObject);
        if (isDuplicate) {
          const oldOnesDate = stringToDate(
            this.auxBody[isDuplicate.index]["Est. Launch"]
          );
          const newOnesDate = stringToDate(processedObject["Est. Launch"]);

          // replacing product with min date
          if (minDate(oldOnesDate, newOnesDate) === newOnesDate) {
            this.auxBody[isDuplicate.index].Duplicated = "Yes";
            this.leftBehind.push(this.auxBody[isDuplicate.index]);
            this.auxBody.splice(isDuplicate.index, 1);
            this.auxBody.push(processedObject);
            return;
          }

          processedObject.Duplicated = "Yes";
          this.leftBehind.push(processedObject);
          return;
        }

        this.auxBody.push(processedObject);
        auxSegments.push(processedObject["Segment"]);
      } else {
        this.leftBehind.push(processedObject);
      }
    });

    //distinct footnotes
    this.footnotes = collectFootnotes(this.auxBody, this.footnotesColumnName);

    // sorting with initial numbers
    auxSegments = auxSegments.sort();

    //segments mapping rules
    auxSegments = auxSegments.map((value) => mapSegment(value));

    //distinct segments
    auxSegments = auxSegments.filter(
      (value, index, arr) => arr.indexOf(value) === index
    );

    this.auxBody.forEach((row) => {
      // event Types extraction
      this.selectedMonth.forEach((month) => {
        if (row[month]) {
          this.eventTypes.push(this.extractEvent(row[month]).type);
        }
      });
    });

    //distinct event Types
    this.eventTypes = this.eventTypes.filter(
      (type, i) => this.eventTypes.indexOf(type) === i
    );

    this.setState({
      body: this.auxBody,
      segments: auxSegments,
      conference,
    });

    

    // console.log("report5:", {
    //   body: this.auxBody,
    //   segments: auxSegments,
    //   conference,
    // });

    
  }

  segmentTdFix() {
    console.log('segmentTd fired')
    setTimeout(() => {
      const elements = $("td.segmentTd");
      // console.log(elements)
      elements.each(function () {
        const height = $(this).height();
        // console.log(height);
        $(this)
          .find('p')
          .css({ "max-width": height + "px" });
      });
    }, 3000);
  }

  filters = (months) => (
    <div className="filterContainer">
      <FormGroup row>
        {this.state.body &&
          Object.keys(months).map((key, index) => {
            return (
              <div key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={`column-${index}-check`}
                      checked={months[key]}
                      type="checkbox"
                      name={key}
                      onChange={this.handleColVisibilityChange}
                    />
                  }
                  label={key}
                />
              </div>
            );
          })}
      </FormGroup>
    </div>
  )

  render() {
    const { body, companies, segments, months, conference, notAvailable } = this.state;
    const id = "report-4";

    if (notAvailable.length) return InvalidReport({ notAvailable, ...this.props });

    return (
      <div id={id}>
        <ReportBase
          {...this.props}
          exportImg={id}
          filters={this.filters(months)}
        >
        <div id="toImage">
          {body && companies && (
            <table>
              <thead>
                <tr>
                  <th />
                  <th
                    className="bg-red"
                    style={{ textAlign: "center", fontWeight: "bold" }}
                    colSpan="2"
                  >
                    Priority Assets
                  </th>
                  {Object.keys(months).map((key, index) => {
                    if (!months[key]) return null;
                    return (
                      <th key={index} className="month-headers">
                        {key}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="3" className="conference">
                    <strong>Conferences</strong>
                  </td>
                  {Object.keys(months).map((key, index) => {
                    if (!months[key]) return null;
                    return conference ? (
                      <td key={`conferences-${index}`}>{conference[index]}</td>
                    ) : (
                      <td key={`conferences-${index}`} />
                    );
                  })}
                </tr>
                {segments.map((segment, si) => {
                  const filteredRows = body.filter(
                    (row) => mapSegment(row["Segment"]) === segment
                  );
                  const formatedRowData = filteredRows.map((row) => {
                    const companyNames = row["Company Name"].split("/");
                    const logos = companyNames.map((name) => {
                      const logo = companies.filter(
                        (company) =>
                          company.name.trim().toLowerCase() ===
                          name.trim().toLowerCase()
                      );

                      return logo[0];
                    });

                    // const footnotesIndex = row["report-4-footnotes"]
                    //   ? this.footnotes.indexOf(row["report-4-footnotes"]) + 1
                    //   : false;
                    let footnotesIndex = null;
                    if (row[this.footnotesColumnName]) {
                      footnotesIndex = (
                        <span className="footnotesIndex">
                          {row[this.footnotesColumnName]
                            .split("|")
                            .map((note) => {
                              let index = -1;
                              this.footnotes.some((n, i) => {
                                if (n.text === note.trim()) {
                                  index = i;
                                  return true;
                                }
                                return false;
                              });
                              if (index === -1) return null;
                              return <span key={index}>({index + 1})</span>;
                            })}
                        </span>
                      );
                    }

                    return {
                      // companyNames,
                      logos,
                      segment: segment,
                      third: [
                        row["Product name"],
                        row["MOA (Revised)"],
                        row["Compound phase"],
                      ],
                      events: this.selectedMonth.map((m) => row[m] && row[m].trim()),
                      footnotesIndex,
                    };
                  });
                  return formatedRowData.map((rowObj, rowIn) => {
                    return (
                      <tr key={segment + rowIn}>
                        <td className="companyLogos">
                          {rowObj.logos.map((logo, index) => {
                            return logo ? (
                              <img
                                key={rowIn + "logo" + index}
                                src={logo.logo}
                                alt={logo.name}
                              />
                            ) : (
                              <span key={rowIn + "not-available" + index}>
                                {" "}
                                N/A{" "}
                              </span>
                            );
                          })}
                        </td>
                        {rowIn === 0 && (
                          <td
                            className="segmentTd"
                            rowSpan={formatedRowData.length}
                          >
                            <div>
                              <p>
                                {rowObj.segment}
                              </p>
                            </div>
                          </td>
                        )}
                        <td className="thirdCol">
                          <span>
                            {rowObj.third[0]}
                            {
                              rowObj.footnotesIndex
                              // && (
                              //   <span className="footnotesIndex">
                              //     ({rowObj.footnotesIndex})
                              //   </span>
                              // )
                            }
                          </span>
                          <span style={{ fontStyle: "italic" }}>
                            {rowObj.third[1]}
                          </span>
                          <span>{rowObj.third[2]}</span>
                        </td>
                        {rowObj.events.map((event, evIn) => {
                          //checking the visiblity
                          if (!months[Object.keys(months)[evIn]]) return null;

                          //checking for same event to draw a line
                          if (
                            evIn > 0 &&
                            event &&
                            rowObj.events[evIn - 1] === event
                          ) {
                            // it's not the last and not equal to the imidiate next one... end of the continuas event.
                            if (
                              evIn !== rowObj.length - 1 &&
                              rowObj.events[evIn + 1] !== event
                            )
                              return (
                                <td
                                  key={segment + rowIn + evIn}
                                  className="event-continue-line end"
                                />
                              );

                            return (
                              <td
                                key={segment + rowIn + evIn}
                                className="event-continue-line"
                              />
                            );
                          }

                          if (!event) {
                            return <td key={segment + rowIn + evIn} />;
                          }

                          const eventObj = this.extractEvent(event);

                          return (
                            <td
                              className={event ? "event for-image" : "for-image"}
                              key={segment + rowIn + evIn}
                              style={{
                                backgroundColor: this.eventTypeColor(
                                  eventObj.type
                                ),
                                color: this.eventTypeFontColor(eventObj.type),
                              }}
                            >
                              {eventObj.name}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  });
                })}
              </tbody>
            </table>
          )}
          {!(body && companies) &&
            <SkeletonTheme color="#E2DEDE">
              <p>
                <Skeleton height={70} />
                <br />
                <br />
                <Skeleton count={5} height={50} />
              </p>
            </SkeletonTheme>
          }
          {body && companies && (
            <Footnotes general={this.generalfootnotes} notes={this.footnotes}>
              <div className="eventTypes">
                {this.eventTypes.map((type, i) => (
                  <p
                    key={i}
                    style={{
                      backgroundColor: this.eventTypeColor(type),
                      color: this.eventTypeFontColor(type),
                    }}
                  >
                    {type}
                  </p>
                ))}
              </div>
            </Footnotes>
          )}
        </div>
        </ReportBase>
        {body && companies && (
          <InvalidRecords
            headers={this.invalidHeaders}
            totalRecord={this.props.data.length - 1}
            leftBehind={this.leftBehind.map((row) =>
              this.invalidHeaders.map((val) => row[val])
            )}
          />
        )}
      </div>
    );
  }

  handleColVisibilityChange = (e) => {
    const key = e.target.name;
    const months = { ...this.state.months };
    months[key] = !months[key];
    this.setState({ months });
  };

  handleFootnotesvisibility = () => {
    const footnotesVisible = this.state.footnotesVisible;
    this.setState({ footnotesVisible: !footnotesVisible });
  };

  extractEvent(event) {
    const eventArr = event.split(",");
    if (eventArr.length === 1) return { name: event, type: "Trial" };
    const type = eventArr.splice(eventArr.length - 1, 1)[0].trim();
    return { name: eventArr.join(), type };
  }

  eventTypeColor(type) {
    if (type.toLowerCase().includes("development")) return "#FFEDAE";
    if (type.toLowerCase().includes("commercial")) return "#006FBB";
    return "#f4cccc";
  }

  eventTypeFontColor(type) {
    // if (type.toLowerCase().includes("development")) return "#FFEDAE";
    if (type.toLowerCase().includes("commercial")) return "#FFFFFF";
    return "inherit";
  }
}

export default Report4;
