import React, { Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import ClientsIcon from "../../../assets/img/SideMenu/clients.svg";
import NewsIcon from "../../../assets/img/SideMenu/news.svg";
import AdministrationIcon from "../../../assets/img/SideMenu/administration.svg";
import companyLogos from "../../../assets/img/SideMenu/companyLogos.svg";
import * as Routes from "./../../../constants/routes";
import Tooltip from "@material-ui/core/Tooltip";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import "./listItems.css";

import { AuthContext } from "../../../auth/AuthContext";
import { Collapse, Divider } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { toggleMenu } from "../../../redux/layout/actions";
import { setCurrentClient } from "../../../redux/app/actions";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
        color: "white",
        backgroundColor: "darkred",
      },
    },
  },
  typography: {
    useNextVariants: true,
  },
});

const styles = (theme) => ({
  listItemText: {
    fontFamily: 'PoppinsSemiBold',
    fontSize: 12
  },
  nested: {
    paddingLeft: theme.spacing(7),
    backgroundColor: 'rgba(204,195,195, .1)'
  },

});
class drawerItems extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = { selected: 'clients', expandItem: null };
  }

  handleClick = (value) => {
    if (value === 'clients')
      this.props.dispatchSetCurrentClient(null);

    this.setState({ selected: value })
  }

  handleAdministrationClick = () => {
    const selected = 'administration';
    if (!this.props.openMenu) {
      this.setState({ selected });
      this.props.dispatchToggleMenu(true);
    }
    else if (this.state.selected === selected) {
      this.setState({ selected: null });
    }
    else {
      this.setState({ selected });
      this.props.dispatchToggleMenu(true);
    }
  }

  render() {
    const { classes, openMenu, client } = this.props;
    const { selected } = this.state;
    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <div>
            <ListItem
              button
              component={Link}
              to="/"
              onClick={() => this.handleClick('clients')}
              selected={selected === 'clients'} >
              <Tooltip title="Clients" placement="right">
                <ListItemIcon>
                  <img src={ClientsIcon} alt="categories icon" />
                </ListItemIcon>
              </Tooltip>
              <ListItemText classes={{ primary: classes.listItemText }} primary={this.props.permitted.length === 1 ? "Therapeutic Areas" : "Clients"} />
            </ListItem>
            <Divider />

            {Boolean(client) && <Fragment>
              <ListItem
                button
                component={Link}
                to={Routes.NEWS}
                onClick={() => this.handleClick('reports')}
                selected={selected === 'reports'}>
                <Tooltip title="News" placement="right">
                  <ListItemIcon>
                    <img src={NewsIcon} alt="reports icon" />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText classes={{ primary: classes.listItemText }} primary="News" />
              </ListItem>
              <Divider />
            </Fragment>}

            {this.context.isAdmin() &&
              <Fragment>
                <ListItem button selected={selected === 'administration'} onClick={this.handleAdministrationClick}>
                  {/* <NavLink to="/administration" activeClassName='listItemSelected'> */}
                  <Tooltip title="Administration" placement="right">
                    <ListItemIcon>
                      <img src={AdministrationIcon} alt="administration" />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText classes={{ primary: classes.listItemText }} primary="Administration" />
                  {/* </NavLink> */}
                  {selected === 'administration' && openMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItem>


                <Divider />

                <Collapse in={selected === 'administration' && openMenu} unmountOnExit>

                  {this.context.onlyAdmin() &&
                    <Fragment>
                      <NavLink to={`${Routes.ADMINISTRATION}/${Routes.CLIENT}`} activeClassName="listItemSelected">
                        <ListItem button className={classes.nested}>
                          <span className="dot"></span>
                          <ListItemText classes={{ primary: classes.listItemText }} primary="Clients" />
                        </ListItem>
                      </NavLink>
                      <Divider />
                    </Fragment>
                  }

                  <NavLink to={`${Routes.ADMINISTRATION}/${Routes.COMPANY}`} activeClassName="listItemSelected">
                    <ListItem button className={classes.nested}>
                      <span className="dot"></span>
                      <ListItemText classes={{ primary: classes.listItemText }} primary="Companies" />
                    </ListItem>
                  </NavLink>
                  <Divider />

                  <NavLink to={`${Routes.ADMINISTRATION}/${Routes.COMPOUND}`} activeClassName="listItemSelected">
                    <ListItem button className={classes.nested}>
                      <span className="dot"></span>
                      <ListItemText classes={{ primary: classes.listItemText }} primary="Compounds" />
                    </ListItem>
                  </NavLink>
                  <Divider />

                  <NavLink to={`${Routes.ADMINISTRATION}/${Routes.KEYWORD}`} activeClassName="listItemSelected">
                    <ListItem button className={classes.nested}>
                      <span className="dot"></span>
                      <ListItemText classes={{ primary: classes.listItemText }} primary="Keyword" />
                    </ListItem>
                  </NavLink>
                  <Divider />

                  <NavLink to={`${Routes.ADMINISTRATION}/${Routes.USERS}`} activeClassName="listItemSelected">
                    <ListItem button className={classes.nested}>
                      <span className="dot"></span>
                      <ListItemText classes={{ primary: classes.listItemText }} primary="Users" />
                    </ListItem>
                  </NavLink>
                  <Divider />

                  <NavLink to={`${Routes.ADMINISTRATION}/${Routes.EVENTS}`} activeClassName="listItemSelected">
                    <ListItem button className={classes.nested}>
                      <span className="dot"></span>
                      <ListItemText classes={{ primary: classes.listItemText }} primary="Events" />
                    </ListItem>
                  </NavLink>
                  <Divider />

                  {/*<NavLink to={`${Routes.ADMINISTRATION}/${Routes.TRIAL}`} activeClassName="listItemSelected">
                    <ListItem button className={classes.nested}>
                      <span className="dot"></span>
                      <ListItemText classes={{ primary: classes.listItemText }} primary="Trials" />
                    </ListItem>
                  </NavLink>
                  <Divider />*/}

                </Collapse>

                
                <ListItem
                  button
                  component={Link}
                  to={Routes.MANAGE_COMPANY_LOGOS}
                  onClick={() => this.handleClick('manage-companies')}
                  selected={selected === 'manage-companies'} >
                  <Tooltip title="Manage Companies (Old)" placement="right">
                    <ListItemIcon>
                      <img src={companyLogos} alt="companyLogos" />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText classes={{ primary: classes.listItemText }} primary={"Manage Companies"} />
                </ListItem>
                <Divider />

              </Fragment>

            }

          </div>
          <div />
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { layout, app } = state;
  return {
    openMenu: layout.openMenu,
    client: app.client,
    permitted: app.permitted
  };
};

const mapActionsToProps = {
  dispatchToggleMenu: toggleMenu,
  dispatchSetCurrentClient: setCurrentClient
};

export default compose(withStyles(styles), connect(mapStateToProps, mapActionsToProps))(drawerItems);
