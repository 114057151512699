import React from 'react';
import mapSegment from '../utils/mapSegment';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const allColors = [
    "#e6194b",
    "#3cb44b",
    "#B7950B",
    "#4363d8",
    "#f58231",
    "#911eb4",
    "#2471A3",
    "#f032e6",
    "#117864",
    "#943126",
    "#008080",
    "#7D3C98",
    "#9a6324",
    "#717D7E",
    "#2E4053",
];

const Segment = ({ segment, distinctSegments, colors }) => {
    let bgcolor = 'red';
    if(colors) {
        const index = distinctSegments.indexOf(segment);
    
        if (index === -1 || index >= allColors.length) 
            bgcolor = allColors[allColors.length - 1]
        else
            bgcolor = allColors[index];
    }

    return (<span
        style={{
            backgroundColor: bgcolor,
            color: "white",
            fontWeight: colors ? 'normal' : 'bold'
        }}
        className="badge badge-danger"
    >
        {mapSegment(segment)}
    </span>);
}

export default Segment;


export function collectDistinctSegments(auxBody) {
    const distinctSegments = [];
    auxBody.forEach((row) => {
        const segment = row['Segment'];
        if (segment && !distinctSegments.includes(segment)) {
            distinctSegments.push(segment);
        }
    });

    return distinctSegments;
}


/**
 * @param {Object} params - {checked, onChange} 
 */
 export function SwitchColor(params) {

    return (
        <FormControlLabel
            control={
                <Checkbox
                    id={`segmentColorSwitch`}
                    type="checkbox"
                    name={`segmentColorSwitch`}
                    // checked={Boolean(tm)}
                    // onChange={redirect}
                    {...params}
                />
            }
            label={"Distinct Segment Colors"}
        />
    )
}