import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { sanitize } from "../export-button/ExportButton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import {
  stringToDate,
  minDate,
  isThisDuplicate,
  collectFootnotes,
} from "../utils/utilities";
import { verifyColumns } from "../utils/dataService";
import mapSegment from "../utils/mapSegment";
import InvalidRecords from "../InvalidRecords";
import Footnotes from "../footnotes/Footnotes";

import { TM, tmMap } from "./TM";
import BoxSizeControl from '../report1/BoxSizeControl';
import InvalidReport from "../groupReports/InvalidReport";
import ReportBase from "../groupReports/ReportBase";
import "./styles.scss";
import RedirectToTm from "../report1/RedirectToTm";
import Segment, { collectDistinctSegments, SwitchColor } from "../report1/Segments";

class Report2 extends Component {
  footnotesColumnName = "report-1-footnotes";
  state = { formated: null, boxSize: { height: 180, width: 185 }, notAvailable: [], randomColors: false };

  headersAsKeys = [
    "H/M/L; New; NA",
    "NCT link",
    "Compound phase",
    "Product name",
    "MOA (Revised)",
    "Company Name",
    "Segment",
    "Condition treated",
    "Est. Launch",
  ];

  invalidHeaders = [
    "H/M/L; New; NA",
    "NCT link",
    "Compound phase",
    "Product name",
    "MOA (Revised)",
    "Company Name",
    "Segment",
    "Est. Launch",
    "Duplicated",
  ];

  formated = {
    "Pre-Clinical": { visibility: false, value: [] },
    "Phase I": { visibility: true, value: [] },
    "Phase II": { visibility: true, value: [] },
    "Phase III": { visibility: true, value: [] },
    Filed: { visibility: false, value: [] },
    "Approved or Marketed": { visibility: false, value: [] },
  };

  // leftBehindHeaders = ["H/M/L; New; NA", "NCT link", "Product name"];

  leftBehind = [];
  footnotes = [];
  generalFootnotes = [];
  data = null;

  validation() {
    const notAvailable = verifyColumns(this.props.data, ["Therapeutic Modality", ...this.headersAsKeys]);
    if (notAvailable.length) {
      this.setState({ notAvailable })
      return false;
    }
    return true;
  }

  componentDidMount() {
    this.data = this.props.data;
    if (!this.validation()) return;

    this.data = this.data;

    const fileHeaders = this.data[0];
    let auxBody = [];
    this.data.forEach((row, index) => {
      if (index === 0) return;

      const processedObject = row.reduce((object, cell, index) => {
        object[fileHeaders[index]] = cell;
        return object;
      }, {});

      //collecting general footnotes
      if (processedObject["report-1-general-footnotes"]) {
        this.generalFootnotes.push(
          processedObject["report-1-general-footnotes"]
        );
      }

      if (["HH", "Medium", "Low"].includes(processedObject["H/M/L; New; NA"])) {
        const isDuplicate = isThisDuplicate(auxBody, processedObject);
        if (isDuplicate) {
          const oldOnesDate = stringToDate(
            auxBody[isDuplicate.index]["Est. Launch"]
          );
          const newOnesDate = stringToDate(processedObject["Est. Launch"]);

          // replacing product with min date
          if (minDate(oldOnesDate, newOnesDate) === newOnesDate) {
            auxBody[isDuplicate.index].Duplicated = "Yes";
            this.leftBehind.push(auxBody[isDuplicate.index]);
            auxBody.splice(isDuplicate.index, 1);
            auxBody.push(processedObject);
            return;
          }

          processedObject.Duplicated = "Yes";
          this.leftBehind.push(processedObject);
          return;
        }

        auxBody.push(processedObject);
      } else {
        this.leftBehind.push(processedObject);
      }
    });

    // collecting distinct segments
    this.distinctSegments = collectDistinctSegments(auxBody);

    //Ditinct Product rule - product name, compound and condition are same (different segment)
    //merging the products
    const distinctProduct = [];
    auxBody.forEach((row) => {
      let found = false;
      const dupRow = { ...row };
      for (let ritem of distinctProduct) {
        if (
          ritem["Product name"] === row["Product name"] &&
          ritem["Compound phase"] === row["Compound phase"] &&
          ritem["Condition treated"] === row["Condition treated"] &&
          ritem["Company Name"] === row["Company Name"] &&
          ritem["NCT link"] === row["NCT link"]
        ) {
          //same product name, compound phase and condition treated
          found = true;
          // for first time replace
          if (typeof ritem["Segment"] === "string") {
            ritem["Segment"] = [ritem["Segment"], dupRow["Segment"]];
          }
          // second or fruther replace
          else {
            ritem["Segment"].push(row["Segment"]);
          }
          const tmStr = "Therapeutic Modality";
          ritem[tmStr] = ritem[tmStr]
            ? `${ritem[tmStr]},${dupRow[tmStr]}`
            : dupRow[tmStr];

          break;
        }
      }
      if (!found) distinctProduct.push(dupRow);
    });
    auxBody = distinctProduct;

    //collecting footnotes
    this.footnotes = collectFootnotes(auxBody, this.footnotesColumnName);

    try {
      // Map coumpund phase to actual phase
      // const mapedToPhase = [];
      // Phase III
      auxBody = auxBody.filter((row) => {
        if (row["Compound phase"].match(/III/)) {
          row["Compound phase"] = "Phase III";
          this.formated["Phase III"].value.push(row);
          return false;
        }
        return true;
      });

      // Phase II
      auxBody = auxBody.filter((row) => {
        if (row["Compound phase"].match(/II/)) {
          row["Compound phase"] = "Phase II";
          this.formated["Phase II"].value.push(row);
          return false;
        }
        return true;
      });

      // Phase I
      auxBody = auxBody.filter((row) => {
        if (row["Compound phase"].match(/I/)) {
          row["Compound phase"] = "Phase I";
          this.formated["Phase I"].value.push(row);
          return false;
        }
        return true;
      });

      // "Pre-clinical"
      auxBody = auxBody.filter((row) => {
        if (row["Compound phase"].toLowerCase().includes("clinical")) {
          this.formated["Pre-Clinical"].value.push(row);
          this.formated["Pre-Clinical"].visibility = true;
          return false;
        }
        return true;
      });

      //Filed
      auxBody = auxBody.filter((row) => {
        if (
          [
            "sNDA",
            "Filed",
            "sNDA (RTF Received)",
            "Filed (CRL Received)",
            "Filed in US (RTF Received), Filed in EU",
            "Filed (RTF Received)",
          ]
            .map((s) => s.toLowerCase())
            .includes(row["Compound phase"].toLowerCase())
        ) {
          this.formated.Filed.value.push(row);
          this.formated.Filed.visibility = true;
          return false;
        }
        return true;
      });

      // "Approved or Marketed"
      auxBody = auxBody.filter((row) => {
        if (
          [
            "Approved",
            "Marketed",
            "Approved in US, Filed in EU",
            "Approved in EU, Filed in US",
            "Marketed in EU, Filed in US",
            "Marketed in US, Filed in EU",
            "Marketed (JP), NDA resubmission in US",
            "Marketed (Russia)",
          ]
            .map((s) => s.toLowerCase())
            .includes(row["Compound phase"].toLowerCase())
        ) {
          this.formated["Approved or Marketed"].value.push(row);
          this.formated["Approved or Marketed"].visibility = true;
          return false;
        }
        return true;
      });

      // push everything left to leftBehind
      if (auxBody.length) {
        auxBody.forEach((row) => this.leftBehind.push(row));
      }

      // Ordering feature within each phase
      Object.keys(this.formated).forEach((key) => {
        const rows = this.formated[key].value;
        if (!rows.length) return;

        const ordered = [];

        //separating those have tm
        const withTm = [];
        rows.filter((row, i) => {
          if (row["Therapeutic Modality"].trim()) {
            withTm.push(rows.splice(i, 1)[0]);
          }
          return null;
        });

        // putting other rows bottom of withTm
        let i = 0;
        while (i < rows.length) {
          if (rows[i]["Therapeutic Modality"].trim()) {
            withTm.push(rows.splice(i, 1)[0]);
          } else i++;
        }

        Object.keys(tmMap).forEach((tm) => {
          // if (!withTm.length) return;
          const selected = [];
          let i = 0;
          while (i < withTm.length) {
            const rowTm = withTm[i]["Therapeutic Modality"]
              .split(",")[0]
              .trim()
              .toLowerCase();

            if (rowTm === tm) {
              selected.push(withTm[i]);
              withTm.splice(i, 1);
              // continue;
            } else i++;
          }

          if (!selected.length) return;

          // sorting the selected ones by their product name
          selected.sort((a, b) => {
            if (a["Product name"] < b["Product name"]) return -1;

            if (a["Product name"] > b["Product name"]) return 1;
            return 0;
          });
          selected.forEach((row) => ordered.push(row));
        });

        this.formated[key].value = [...ordered, ...withTm, ...rows];
      });

      console.log("report2:", this.formated);

      this.setState({ formated: this.formated });
    } catch (error) {
      console.log(error)
      alert("Error occured while processing report data");
      // this.props.history.push("/");
      // return;
    }
    // });
  }

  switchColor = (e) => {
    this.setState({randomColors: e.target.checked})
  }

  filters = (formated) => (
    <div className="filterContainer" style={{ marginLeft: 10 }}>
      <FormGroup row>
        {formated &&
          Object.keys(this.formated).map((key, index) => {
            return (
              <div key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={`column-${index}-check`}
                      checked={this.state.formated[key].visibility}
                      type="checkbox"
                      name={key}
                      onChange={this.handleColVisibilityChange}
                    />
                  }
                  label={key}
                />
              </div>
            );
          })}
      </FormGroup>
      
      <FormGroup row>
        <RedirectToTm/>
        <SwitchColor onChange={this.switchColor} checked={Boolean(this.state.randomColors)}></SwitchColor>
      </FormGroup>
    </div>
  )



  render(props) {
    const { formated, boxSize, notAvailable, randomColors } = this.state;
    const id = "report-1-tm";

    if (notAvailable.length) return InvalidReport({ notAvailable, ...this.props });

    return (
      <div id={id}>
        <ReportBase
          {...this.props}
          exportImg={id}
          filters={this.filters(formated)}
          toolbarRightComponent={(
            <BoxSizeControl
              className="boxSizeControl"
              onChange={this.boxSizeChange}
              value={boxSize}
            />
          )}
        >
          <div id="toImage">
            {formated && (
              <table>
                <thead>
                  <tr>
                    {Object.keys(this.formated).map((key, index) => {
                      if (formated[key].visibility)
                        return <th key={`head-${index}`}>{key}</th>;
                      return null;
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {Object.keys(this.formated).map((key) => {
                      // let sameProduct = null;
                      if (formated[key].visibility) {
                        return (
                          <td key={key}>
                            <div className="holder">
                              {formated[key].value.map((row, index) => {
                                return (
                                  <div
                                    key={key + index}
                                    style={{
                                      minHeight: boxSize.height + "px",
                                      width: boxSize.width + "px",
                                    }}
                                    className="box"
                                  >
                                    <div className="box-texts">
                                      <strong>
                                        {sanitize(row["Product name"])}
                                        {row[this.footnotesColumnName] && (
                                          <span className="footnotesIndex">
                                            {row[this.footnotesColumnName]
                                              .split("|")
                                              .map((note) => {
                                                let index = -1;
                                                this.footnotes.some((n, i) => {
                                                  if (n.text === note.trim()) {
                                                    index = i;
                                                    return true;
                                                  }
                                                  return false;
                                                });
                                                if (index === -1) return null;
                                                return (
                                                  <span key={index}>
                                                    ({index + 1})
                                                  </span>
                                                );
                                              })}
                                            {/* (
                                        {this.footnotes.indexOf(
                                          row[this.footnotesColumnName]
                                        ) + 1}
                                        ) */}
                                          </span>
                                        )}
                                      </strong>
                                      <p className="for-image">{row["MOA (Revised)"]}</p>
                                      <p className="for-image">
                                        {row["Company Name"]}
                                      </p>
                                      {typeof row["Segment"] === "string" &&
                                        row["Segment"] && (
                                          <Segment segment={row['Segment']} distinctSegments={this.distinctSegments} colors={randomColors} />
                                        )}
                                      {typeof row["Segment"] === "object" &&
                                        row["Segment"].map((seg, segin) => (
                                          <p key={seg + segin}>
                                            <Segment segment={seg} distinctSegments={this.distinctSegments} colors={randomColors} />
                                          </p>
                                        ))}
                                    </div>

                                    {row["Therapeutic Modality"] && (
                                      <div className="tm-container">
                                        <TM tm={row["Therapeutic Modality"]}></TM>
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </td>
                        );
                      }
                      return null;
                    })}
                  </tr>
                </tbody>
              </table>
            )}

            {formated && (
              <Footnotes
                general={this.generalFootnotes}
                notes={this.footnotes}
              ></Footnotes>
            )}
          </div>
        </ReportBase>


        {formated && (
          <InvalidRecords
            headers={this.invalidHeaders}
            totalRecord={this.data.length - 1}
            leftBehind={this.leftBehind.map((row) =>
              this.invalidHeaders.map((v) => row[v])
            )}
          />
        )}
        {!formated && (
          <SkeletonTheme color="#E2DEDE">
            <p>
              <Skeleton height={100} />
              <br />
              <br />
              <Skeleton count={8} height={60} />
            </p>
          </SkeletonTheme>
        )}
      </div>
    );
  }

  handleColVisibilityChange = (e) => {
    const key = e.target.name;

    const formated = { ...this.state.formated };
    formated[key] = {
      visibility: e.target.checked,
      value: formated[key].value,
    };
    this.setState({ formated });
  };

  boxSizeChange = (e) => {
    const { boxSize } = this.state;
    boxSize[e.target.name] = e.target.value;
    this.setState({ boxSize });
  };
}

export default Report2;
